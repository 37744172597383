import { useCompleteEmailResetMutation } from '@coin-microservices/auth-change-email-react-hooks'
import { AlertTitle, Box, Paper, TextField } from '@mui/material'
import { getAuth, updateEmail } from 'firebase/auth'
import get from 'lodash/get'
import { FormEventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { LoadingButton, useSnackbar } from '../components'
import { ChangeEmailProvider, verifyRequest } from '../context'
import CustomBasePage from '../CustomBasePage'
import logger from '../Log'
import { parseError } from '../utils'

const ChangeEmailView = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [request, setRequest] = useState<{ id?: string | null } | null>(null)
  const [errors, setErrors] = useState<{ email?: string; emailConfirm?: string }>({})

  const [, { setSnackbar }] = useSnackbar()
  const [completeEmailReset, complete] = useCompleteEmailResetMutation()

  const code = request?.id

  const onSubmit: FormEventHandler<Element> = async (ev) => {
    ev.preventDefault()
    if (!code) return
    const email = get(ev, 'target.email.value')
    const emailConfirm = get(ev, 'target.emailConfirm.value')
    if (!email) return setErrors({ email: 'Email is required' })
    if (!emailConfirm) return setErrors({ emailConfirm: 'Email confirmation is required' })
    if (email !== emailConfirm) return setErrors({ emailConfirm: 'Email confirmation is invalid' })

    const user = getAuth().currentUser
    if (user) {
      try {
        await updateEmail(user, email)
        await completeEmailReset({
          variables: { email, id: code },
        })
      } catch (err) {
        setErrors({ email: parseError(err) })
        return
      }
      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Email Updated</AlertTitle>
            Your email was successfully changed to <strong>{email}</strong>
          </>
        ),
        severity: 'success',
      })
    }
  }

  useEffect(() => {
    setSnackbar({
      loading: true,
      message: 'Verifying request',
      severity: 'info',
    })

    verifyRequest(params?.code ?? '')
      .then((request) => {
        setSnackbar({
          autoHideDuration: 10000,
          message: (
            <>
              <AlertTitle>Verified</AlertTitle>
              This is a one time link if you refresh or navigate away you will need a new link.
            </>
          ),
          severity: 'success',
        })
        setRequest(request)
      })
      .catch((err) => {
        logger().error(err)
        setSnackbar({
          autoHideDuration: 6000,
          message: parseError(err),
          severity: 'error',
        })
        navigate('/')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.code])

  return (
    <CustomBasePage container="sm">
      <Paper>
        <Box p={2}>
          <form onSubmit={onSubmit}>
            <h1 style={{ fontWeight: 100 }}>Update Email</h1>
            <TextField
              name="email"
              label="New email"
              helperText={errors.email ?? ''}
              error={Boolean(errors.email)}
              variant="outlined"
              margin="normal"
              disabled={!code}
              fullWidth
            />
            <TextField
              name="emailConfirm"
              label="Confirm email"
              helperText={errors.emailConfirm ?? ''}
              error={Boolean(errors.emailConfirm)}
              variant="outlined"
              margin="normal"
              disabled={!code}
              fullWidth
            />
            <Box pt={2}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                disabled={!code}
                loading={complete.loading}
                fullWidth
              >
                Update
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Paper>
    </CustomBasePage>
  )
}

export default () => {
  return (
    <ChangeEmailProvider>
      <ChangeEmailView />
    </ChangeEmailProvider>
  )
}
