import { getAuth } from "firebase/auth";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { ILimitedRelease, ILimitedReleasePurchaseResult, ILimitedReleaseResult } from "./types";

// const uri = "http://localhost:8008";
const uri = "https://coin-service-username-generator-zatxacztoa-uc.a.run.app";

const getAuthHeader = async () => {
  const user = getAuth().currentUser;
  const token = user ? await user.getIdToken() : "";
  return {
    "Content-Type": "application/json",
    "X-Auth-Token": token,
  };
};

async function loadLimitedRelease(path: string): Promise<ILimitedReleaseResult> {
  const headers = await getAuthHeader();
  const response = await fetch(`${uri}${path}`, { headers });
  return response.json();
}

async function purchaseLimitedRelease(
  path: string,
  { arg }: { arg: { itemId: string } }
): Promise<ILimitedReleasePurchaseResult> {
  const headers = await getAuthHeader();
  const response = await fetch(`${uri}${path}`, {
    method: "POST",
    body: JSON.stringify(arg),
    headers,
  });
  return response.json();
}

async function bidLimitedRelease(
  path: string,
  { arg }: { arg: { itemId: string; amount: number } }
): Promise<ILimitedReleasePurchaseResult> {
  const headers = await getAuthHeader();
  const response = await fetch(`${uri}${path}`, {
    method: "POST",
    body: JSON.stringify(arg),
    headers,
  });
  return response.json();
}

export function useLimitedRelease(id: string) {
  return useSWR(`/limited/release/${id}`, loadLimitedRelease, {
    // refreshInterval: 5000
  });
}

export function useLimitedReleasePurchase(id: string) {
  return useSWRMutation(
    `/limited/release/purchase/${id}`,
    purchaseLimitedRelease
  );
}

export function useLimitedReleaseBid(id: string) {
  return useSWRMutation(
    `/limited/release/bid/${id}`,
    bidLimitedRelease
  );
}
