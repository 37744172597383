import SoldIcon from "@mui/icons-material/MoneyOff";
import {
  Avatar,
  Box, Button, Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip, Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CoinLogo from "../../assets/CoinLogo";
import { useLimitedReleaseConfirm } from "./Confirm";
import { ILimitedRelease, ILimitedReleaseItem } from "./types";

export const LimitedReleaseCard = ({ release, item }: { release: ILimitedRelease | undefined; item: ILimitedReleaseItem }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.paper",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        boxShadow: 3,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: 6,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "100%",
        }}
      >
        <CardMedia
          component="img"
          image={item.imageUrl}
          alt={item.name}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: item.sold ? "grayscale(100%)" : "none",
          }}
        />
        {item.sold && (
          <Chip
            label="SOLD"
            color="error"
            icon={<SoldIcon />}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              fontWeight: "bold",
            }}
          />
        )}
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          variant="h6"
          component="h3"
          gutterBottom
          sx={{ whiteSpace: isMdUp ? "nowrap" : "normal", fontSize: "1.15rem" }}
          dangerouslySetInnerHTML={{ __html: item.name.replace(/(\w+) Profile NFT/, '<strong>$1</strong> Profile NFT') }}
        />
        <Typography
          variant="h5"
          color={item.sold ? "text.secondary" : "secondary.main"}
          sx={{ fontWeight: "bold" }}
        >
          {(item.bid || item.bidInitialMinimum || item.amount).toLocaleString(undefined, {
            maximumFractionDigits: 0,
            notation: "compact",
            compactDisplay: "short",
          })}+{" "}
          COIN
        </Typography>
      </CardContent>
      <CardActions>
        <PurchaseItem release={release} item={item} />
      </CardActions>
    </Card>
  );
};

const PurchaseItem = ({ release, item }: { release: ILimitedRelease | undefined; item: ILimitedReleaseItem }) => {
  const { balance, setConfirmationOpen } = useLimitedReleaseConfirm();

  return (
    <Button
      fullWidth
      variant="contained"
      color={item.sold ? "error" : "primary"}
      onClick={() => setConfirmationOpen(item)}
      startIcon={
        item.sold ? <SoldIcon /> : <CoinLogo fill="white" height={32} />
      }
      size="large"
      disabled={item.sold}
      sx={{
        py: 1.5,
        position: "relative",
        fontSize: "1.1rem",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <span style={{ color: "white", whiteSpace: "nowrap" }}>
        {item.sold ? "Sold Out" : release?.isAuction ? "Bid Now" : "Buy Now"}
      </span>
    </Button>
  );
};
