import { Alert, LinearProgress, Snackbar } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { useSnackbar } from './SnackbarProvider'

const Snack = () => {
  const [snackbar, { closeSnackbar }] = useSnackbar()
  const [progress, setProgress] = useState(0)
  const intervalId = useRef<NodeJS.Timeout>()
  const open = Boolean(snackbar.message)
  useEffect(() => {
    if (open && snackbar.autoHideDuration) {
      setProgress(0)
      intervalId.current = setInterval(() => {
        setProgress((v) => {
          if (v >= 100) {
            if (intervalId.current) {
              clearInterval(intervalId.current)
            }
          }
          return v + 10
        })
      }, snackbar.autoHideDuration / 10)
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [open, snackbar.autoHideDuration])
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      open={open}
      color="danger"
      transitionDuration={0}
      autoHideDuration={snackbar.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert
        style={{ position: 'relative' }}
        severity={snackbar.severity}
        onClose={
          snackbar.loading
            ? () => {
                return
              }
            : closeSnackbar
        }
      >
        {snackbar.loading ? <LinearProgress style={{ left: 0, position: 'absolute', top: 0, width: '100%' }} /> : null}
        {snackbar.autoHideDuration ? (
          <LinearProgress
            style={{ left: 0, position: 'absolute', top: 0, width: '100%' }}
            value={progress}
            variant="determinate"
          />
        ) : null}
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

export default Snack
