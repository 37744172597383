import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { ILearnAndEarnOffer } from "./types";
import { useSnackbar } from "../Snackables";
import { parseError } from "../../utils";
import { getAuth } from "firebase/auth";

// const apiUrl = "http://localhost:8090";
const apiUrl = "https://coin-rewards-service-542704523793.us-central1.run.app";

const SKELETON_ITEM_COUNT = 2;

const getAuthHeader = async () => {
  const user = getAuth().currentUser;
  const token = user ? await user.getIdToken() : "";
  return {
    "X-Auth-Token": token,
  };
};

const fetchLearnAndEarnOffersList = async (): Promise<{
  result: ILearnAndEarnOffer[];
}> => {
  const headers = await getAuthHeader();
  return fetch(`${apiUrl}/learn/offers/list`, {
    headers,
  }).then((res) => res.json());
};

interface ISubmitParams {
  _id: string;
  answers: Record<string, number>;
  recaptchaToken?: string;
}

export interface ILearnAndEarnOfferSubmitResult {
  correctAnswers: number;
  incorrectAnswerIndexes: number[];
  amount: number;
}

const fetchLearnAndEarnOffersSubmit = async (
  _: string,
  params: { arg: ISubmitParams }
): Promise<ILearnAndEarnOfferSubmitResult> => {
  const headers = await getAuthHeader();
  return fetch(`${apiUrl}/learn/offers/submit`, {
    method: "POST",
    body: JSON.stringify(params.arg),
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  })
  .then((res) => res.json())
  .then((data) => {
    if (!data.success) {
      throw new Error(data.message);
    }

    return data.result;
  });
};

export const useLearnAndEarnOffers = () => {
  const { data, isLoading, mutate } = useSWR(
    `/learn/offers/list`,
    fetchLearnAndEarnOffersList
  );

  const offers: ILearnAndEarnOffer[] = isLoading
    ? new Array(SKELETON_ITEM_COUNT).fill({})
    : data?.result ?? [];

  return { offers, isLoading, mutate };
};

export const useLearnAndEarnSubmit = () => {
  const { mutate } = useSWRConfig()
  const { data, isMutating, trigger } = useSWRMutation(
    `/learn/offers/submit`,
    fetchLearnAndEarnOffersSubmit
  );
  const { setSnackbar } = useSnackbar()[1];

  const handleSubmit = async (params: ISubmitParams) => {
    try {
      const result = await trigger(params);
      await mutate(`/learn/offers/list`);
      // setSnackbar({
      //   autoHideDuration: 6000,
      //   message: "Successfully submitted!",
      //   severity: "success",
      // });
      return result;
    } catch (err) {
      const error = err as Error;
      console.error("LearnAndEarnSubmit error", err);
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error.message),
        severity: "error",
      });

      return null;
    }
  };

  return { data, isMutating, handleSubmit };
};
