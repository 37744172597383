import { AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, LinearProgress, Typography, Box, TextField, ListItem, ListItemAvatar, Avatar, ListItemText, Slider, Alert, ListItemSecondaryAction, IconButton, List } from "@mui/material";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { parseError, numberFormat } from "../../utils";
import { useSnackbar } from "../Snackables";
import { useLimitedReleasePurchase, useLimitedReleaseBid } from "./hooks";
import { ILimitedRelease, ILimitedReleaseItem, ILimitedReleaseResult } from "./types";
import { KeyedMutator } from "swr";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../User";
import { CancelOutlined, InfoOutlined, Remove, Star } from "@mui/icons-material";

interface ILimitedReleaseConfirmProviderProps extends PropsWithChildren {
  id: string;
  balance: number;
  nftAddress: string;
  release: ILimitedRelease | undefined;
  mutate: KeyedMutator<ILimitedReleaseResult>;
}

type IOpenState = 'confirm' | 'nowallet' | 'bid' | 'multipleLeaders' | 'removeBid' | '';

interface ILimitedReleaseConfirmContext {
  id: string;
  balance: number;
  nftAddress: string;
  mutate: KeyedMutator<ILimitedReleaseResult>;
  open: IOpenState;
  release: ILimitedRelease | undefined;
  selected?: ILimitedReleaseItem;
  setConfirmationOpen: (selected: ILimitedReleaseItem) => void;
  setOpen: Dispatch<SetStateAction<IOpenState>>;
}

const LimitedReleaseConfirmContext = createContext<null | ILimitedReleaseConfirmContext>(null)
const step = 100

export const useLimitedReleaseConfirm = () => {
  const ctx = useContext(LimitedReleaseConfirmContext)

  if (!ctx) {
    throw new Error("useLimitedReleaseConfirm must be used within a LimitedReleaseConfirmProvider")
  }

  return ctx
}

export const LimitedReleaseConfirmProvider = ({ children, release, ...props }: ILimitedReleaseConfirmProviderProps) => {
  const [open, setOpen] = useState<IOpenState>('')
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const selected = useMemo(() => (release?.items ?? []).find((item) => item.id === selectedId), [release?.items, selectedId])
  const { setSnackbar } = useSnackbar()[1];
  const { user } = useUser()
  const navigate = useNavigate()

  const setConfirmationOpen = (selected: ILimitedReleaseItem) => {
    if (!user) {
      navigate('/auth?redirect=' + encodeURIComponent(window.location.pathname))
      return
    }
    if (props.balance < selected.amount) {
      setSnackbar({
        autoHideDuration: 6000,
        message: "Insufficient funds",
        severity: "error",
      });
      return
    }
    setSelectedId(selected.id)
    setOpen('confirm')
    props.mutate()
  }

  return (
    <LimitedReleaseConfirmContext.Provider value={{ open, release, selected, setConfirmationOpen, setOpen, ...props }}>
      {children}
      <LimitedReleaseConfirmDialog />
    </LimitedReleaseConfirmContext.Provider>
  )
}

const LimitedReleaseConfirmDialog = () => {
  const { id, mutate, nftAddress, release, balance, open, selected, setOpen } = useLimitedReleaseConfirm()
  const { trigger: triggerPurchase, isMutating: isPurchasing } = useLimitedReleasePurchase(id);
  const { trigger: triggerBid, isMutating: isBidding } = useLimitedReleaseBid(id);
  const [bidAmount, setBidAmount] = useState(0);
  const { setSnackbar } = useSnackbar()[1];
  const { user } = useUser();

  const [error, setError] = useState('');

  const t = {
    amount: release?.isAuction ? bidAmount : selected?.amount ?? 0,
    canceledTitle: release?.isAuction ? 'Bid Canceled' : 'Purchase Canceled',
    title: release?.isAuction ? selected?.name?.replace(/(\w+) Profile NFT/, '<strong>$1</strong> Profile NFT') : 'Confirm Purchase',
    confirmationText: `Are you sure you want to ${release?.isAuction ? 'bid for' : 'purchase'} <strong>${selected?.name}</strong>?`,
    currentBid: selected?.bid ?? selected?.bidInitialMinimum ?? 0,
    currentUserBid: selected?.userBid?.amount ? selected?.userBid?.amount : selected?.bidInitialMinimum ?? 0,
    minimumBid: selected?.bidInitialMinimum ?? 0,
  }

  useEffect(() => {
    setBidAmount(t.currentUserBid)
  }, [t.currentUserBid])

  const handleCancel = () => {
    if (!nftAddress) {
      setOpen('nowallet');
    } else {
      setOpen('');
    }
  }

  const handleClose = () => {
    setOpen('')
  }

  const handlePurchase = async () => {
    try {
      setError('')
      if (!selected) return

      if (balance < selected.amount) {
        throw new Error("Insufficient funds")
      }
      
      const result = await triggerPurchase({ itemId: selected.id });

      await mutate();

      if (result.message) {
        throw new Error(result.message);
      }

      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Purchase Successful</AlertTitle> {selected.name} has been
            added to your account <Link to="/profiles">account</Link>.
          </>
        ),
        severity: "success",
      });
      setOpen('');
    } catch (err) {
      // setSnackbar({
      //   autoHideDuration: 6000,
      //   message: parseError(err),
      //   severity: "error",
      // });
      setError(parseError(err))
    }
  };

  const handleSetBidAmount = (amount: number) => {
    try {
      setError('')
      
      if (amount < t.minimumBid) {
        throw new Error("Bid amount too low")
      }

      if (amount > balance) {
        throw new Error("Insufficient funds")
      }
      
      setBidAmount(amount)
    } catch (err) {
      setError(parseError(err))
    }
  }
  
  const handleBid = () => {
    try {
      setError('')
      const amount = Number(bidAmount)
      if (isNaN(amount) || amount <= 0) {
        throw new Error("Invalid amount")
      }
  
      if (balance < amount) {
        throw new Error("Insufficient funds")
      }
      setOpen('bid');
    } catch (err) {
      // setSnackbar({
      //   autoHideDuration: 6000,
      //   message: parseError(err),
      //   severity: "error",
      // });

      setError(parseError(err))
    }
  }

  const handleBidConfirm = async () => {
    try {
      setError('')

      if (!selected) return

      const amount = Number(bidAmount);

      if (!user) {
        throw new Error("You must be logged in to bid")
      }

      // if (user.uid === selected.uid) {
      //   throw new Error("You are already the highest bidder")
      // }

      if (isNaN(amount) || amount <= 0) {
        throw new Error("Invalid amount")
      }

      if (balance < amount) {
        throw new Error("Insufficient funds")
      }
      
      const result = await triggerBid({ itemId: selected.id, amount });

      await mutate();

      if (result.message) {
        throw new Error(result.message);
      }

      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Bid Successful</AlertTitle> {selected.name} you will be notified if you win this auction.
          </>
        ),
        severity: "success",
      });
      setOpen('confirm');
    } catch (err) {
      setError(parseError(err))
    }
  };

  const handleBidRemove = async () => {
    try {
      setError('')

      if (!selected) return

      const result = await triggerBid({ itemId: selected.id, amount: 0 });

      await mutate();

      if (result.message) {
        throw new Error(result.message);
      }

      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Bid Removed</AlertTitle> you are no longer bidding for {selected.name}.
          </>
        ),
        severity: "success",
      });
      setOpen('confirm');
    } catch (err) {
      setError(parseError(err))
    }
  }

  return (
    <>
      <Dialog
        open={open === "confirm"}
        onClose={handleCancel}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle><span dangerouslySetInnerHTML={{ __html: t.title || '' }}></span></DialogTitle>
        {/* <Divider /> */}
        <DialogContent>
          <List>
          <ListItem disablePadding>
            <ListItemText primary={<Typography>
              Highest Bid{' '}
              {selected?.multipleLeaders && (
                <>
                  <span>
                    (Multiple Users)
                  </span>
                  <InfoOutlined onClick={() => setOpen('multipleLeaders')} />
                </>
              )}
            </Typography>} secondary={`${numberFormat(t.currentBid, 0)} COIN`} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={<Typography>
              Your Bid{' '}
              {selected?.multipleLeaders && selected?.userBid?.amount === t.currentBid ? (
                <>
                  <span>
                    (Tied for Highest)
                  </span>
                  <Star />
                </>
              ) : selected?.userBid?.amount === t.currentBid ? (
                <>
                  <span>
                    (Current Leader!)
                  </span>
                  <Star />
                </>
              ) : null}
            </Typography>} secondary={`${numberFormat(selected?.userBid?.amount ?? 0, 0)} COIN`} />

            {selected?.userBid?.amount ? <ListItemSecondaryAction>
              <Tooltip title={<Typography>Remove bid</Typography>}>
                <IconButton onClick={() => setOpen('removeBid')}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction> : null}
          </ListItem>
          </List>

          <TextField
            value={`${numberFormat(bidAmount, 0)} COIN`}
            margin="normal"
            disabled
            fullWidth
          />
          <Box display="flex" gap={2} mb={2}>
            {/* <Box onClick={() => handleSetBidAmount(t.currentBid * 1.1)} sx={{ width:'100%' }}> 
              <Button
                disabled={t.currentBid * 1.1 > balance}
                color={t.currentBid * 1.1 === bidAmount ? 'primary' : 'inherit'}
                variant="contained"
                size="small"
                fullWidth
              >
                +10%
              </Button>
            </Box>
            <Box onClick={() => handleSetBidAmount(t.currentBid * 1.5)} sx={{ width:'100%' }}> 
              <Button
                disabled={t.currentBid * 1.5 > balance}
                color={t.currentBid * 1.5 === bidAmount ? 'primary' : 'inherit'}
                variant="contained"
                size="small"
                fullWidth
              >
                +50%
              </Button>
            </Box>
            <Box onClick={() => handleSetBidAmount(t.currentBid * 2)} sx={{ width:'100%' }}> 
              <Button
                disabled={t.currentBid * 2 > balance}
                color={t.currentBid * 2 === bidAmount ? 'primary' : 'inherit'}
                variant="contained"
                size="small"
                fullWidth
              >
                +100%
              </Button>
            </Box> */}
            {/* <Box onClick={() => handleSetBidAmount(bidAmount - step)}> 
              <Button
                disabled={bidAmount - step < t.minimumBid}
                variant="contained"
                size="small"
              >
                -
              </Button>
            </Box> */}
            <Slider value={bidAmount} min={t.minimumBid} max={balance} step={step} onChange={(ev, value) => {
              if (typeof value === 'number') {
                setBidAmount(value)
              }
            }} />
            {/* <Box onClick={() => handleSetBidAmount(bidAmount + step)}> 
              <Button
                disabled={bidAmount + step > balance}
                variant="contained"
                size="small"
              >
                +
              </Button>
            </Box> */}
          </Box>
          <Box mb={2}>
            <TextRow label="Balance">{numberFormat(balance, 0)} COIN</TextRow>
            <TextRow label="Cost">({numberFormat(t.amount, 0)} COIN)</TextRow>
            <TextRow label="Adjusted">
              {numberFormat(balance - t.amount, 0)} COIN
            </TextRow>
          </Box>
        </DialogContent>
        {/* <Divider /> */}
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={release?.isAuction ? handleBid : handlePurchase}
            variant="contained"
            color="primary"
            disabled={!release || selected?.sold || isPurchasing || isBidding}
            sx={{ position: "relative" }}
          >
            {isPurchasing || isBidding ? (
              <LinearProgress
                sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              />
            ) : null}
            <span style={{ color: "white" }}>{release?.isAuction && selected?.userBid?.amount ? 'Adjust Bid' : release?.isAuction ? 'Place Bid' : 'Confirm'}</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open === 'multipleLeaders'} onClose={() => setOpen('confirm')}>
        <DialogTitle>Multiple Winners</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If multiple users have the same bid when the auction ends, a random user of that group will be selected.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen('confirm')}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open === 'removeBid'} onClose={() => setOpen('confirm')}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove your bid?
          </DialogContentText>
          {error && <Alert color="error" sx={{ mt: 2 }}>
            <AlertTitle>{error}</AlertTitle>
          </Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen('confirm')}>Close</Button>
          <Button
            onClick={handleBidRemove}
            variant="contained"
            color="primary"
            disabled={isBidding}
            sx={{ position: "relative" }}
          >
            {isBidding ? (
              <LinearProgress
                sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              />
            ) : null}
            <span style={{ color: "white" }}>Confirm</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open === "nowallet"}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{t.canceledTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Not to worry, you can return here after setting the NFT address in
            app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open === "bid"}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            By placing this bid you are agreeing that this portion of your COIN balance is used to acquire this NFT. If that amount doesn't exist at the time this auction is resolved then it will go to the next highest bidder.
          </DialogContentText>
          <Box mb={2}>
            <TextRow label="Balance">{numberFormat(balance, 0)} COIN</TextRow>
            <TextRow label="Cost">({numberFormat(t.amount, 0)} COIN)</TextRow>
            <TextRow label="Adjusted">
              {numberFormat(balance - t.amount, 0)} COIN
            </TextRow>
          </Box>
          <DialogContentText paragraph>
            <span dangerouslySetInnerHTML={{ __html: t.confirmationText }} />
          </DialogContentText>
          {nftAddress ? (
            <Tooltip title={<Typography>{nftAddress}</Typography>}>
              <Typography variant="subtitle2">
                Delivering to: {nftAddress.slice(0, 6)}...{nftAddress.slice(-6)}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <Typography>
                  You don&lsquo;t currently have an NFT Wallet set on your
                  account. Not to worry though, you can use the shared COIN NFT
                  address and transfer it to your own address later.
                </Typography>
              }
            >
              <Typography variant="subtitle2">
                Delivering to: COIN Default NFT Address <span>ⓘ</span>
              </Typography>
            </Tooltip>
          )}
          {error && <Alert color="error" sx={{ mt: 2 }}>
            <AlertTitle>{error}</AlertTitle>
          </Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen('confirm')}>Back</Button>
          <Button
            onClick={handleBidConfirm}
            variant="contained"
            color="primary"
            disabled={isBidding}
            sx={{ position: "relative" }}
          >
            {isBidding ? (
              <LinearProgress
                sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              />
            ) : null}
            <span style={{ color: "white" }}>Confirm</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const TextRow = ({ label, children }: PropsWithChildren<{ label: string }>) => {
  return <Box display="flex" justifyContent="space-between">
    <Typography><strong>{label}</strong></Typography>
    {typeof children === 'string' ? <Typography>{children}</Typography> : children}
  </Box>
}