import {
  AppBar,
  Box,
  Container,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Countdown, { CountdownRendererFn, zeroPad } from "react-countdown";

export const StickyCountdown = ({
  isLoading,
  startDate,
  endDate,
  title,
  takeoverMessage,
}: {
  isLoading: boolean;
  startDate?: string;
  endDate?: string;
  title: string;
  takeoverMessage?: string;
}) => {
  const [state, setState] = useState<{
    startDate?: Date;
    endDate?: Date;
    statusMessage?: string;
  }>({});

  const updateTimer = useCallback(() => {
    const currentDate = new Date();

    if (takeoverMessage) {
      setState({
        statusMessage: takeoverMessage,
      });
    } else if (startDate && currentDate < new Date(startDate)) {
      setState({
        startDate: new Date(startDate),
        statusMessage: `${title} available in`,
      });
    } else if (endDate && currentDate < new Date(endDate)) {
      setState({
        endDate: new Date(endDate),
        statusMessage: `${title} available for`,
      });
    } else {
      setState({
        statusMessage: `This ${title.toLowerCase()} has ended.`,
      });
    }
  }, [takeoverMessage, startDate, endDate]);

  const restartTimer = () => {
    setTimeout(() => updateTimer(), 1000)
  }

  useEffect(() => {
    updateTimer()
  }, [takeoverMessage, endDate, startDate]);

  return (
    <AppBar position="sticky" sx={{ bgcolor: "primary.main", marginTop: -4 }}>
      <Container maxWidth="lg">
        <Box sx={{ width: "100%", py: 2, display: "flex", flexDirection: 'column', gridGap: 8 }}>
          <Typography variant="h6" align="center">
            {isLoading ? (
              <Skeleton variant="text" width="100%" />
            ) : state.statusMessage ? (
              <strong>{state.statusMessage}</strong>
            ) : null}
          </Typography>
          {isLoading ? (
            <Typography variant="h6" align="center">
              <Skeleton variant="text" width="100%" />
            </Typography>
          ) : state.startDate ? (
            <Countdown key="startTimer" date={state.startDate} autoStart renderer={renderer} onComplete={() => restartTimer()} />
          ) : state.endDate ? (
            <Countdown key="endTimer" date={state.endDate} autoStart renderer={renderer} onComplete={() => restartTimer()} />
          ) : null}
        </Box>
      </Container>
    </AppBar>
  );
};

const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) => {
  return (
    <Box display="flex" flexWrap="nowrap" gap={2}>
      <CountdownCard value={zeroPad(days)} label="Days" />
      <CountdownCard value={zeroPad(hours)} label="Hours" />
      <CountdownCard value={zeroPad(minutes)} label="Minutes" />
      <CountdownCard value={zeroPad(seconds)} label="Seconds" />
    </Box>
  );
};

const CountdownCard = ({ value, label }: { value: string; label: string }) => {
  return (
    <Paper sx={{ flex: 1, p: 1, textAlign: 'center' }}>
      <Typography variant="h4">{value}</Typography>
      <Typography variant="h6">{label}</Typography>
    </Paper>
  );
};
